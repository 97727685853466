<template>
  <div>
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" dense outlined color="#757575" :label="$t('student name')"
              v-model="student.first_name"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" dense outlined color="#757575" :label="$t('last name')"
              v-model="student.last_name"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" dense outlined color="#757575" :label="$t('father name')"
              v-model="student.father_name"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" dense outlined color="#757575" :label="$t('grand father name')"
              v-model="student.grand_father_name"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" dense outlined color="#757575" :label="$t('mother name')"
              v-model="student.mother_name"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('birthday date')"
              v-model="student.birthday"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" outlined dense color="#757575" v-model="student.birth_location"
              :label="$t('birthday place')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.father_job"
              :label="$t('father career')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.mobile_number" :label="$t('phone number')"
              type="number"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.phone_number" :label="$t('land phone number')"
              type="number"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.foreign_language"
              :label="$t('foreign language')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.nationality"
              :label="$t('nationalty')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.student_email"
              :label="$t('student email')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.parent_email"
              :label="$t('parent email')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.parent_passport_number"
              :label="$t('parent passport number')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.student_passport_number"
              :label="$t('student passport number')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.address"
              :label="$t('address')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.student_contact"
              :label="$t('student contact')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="student.birth_registration_id"
              :label="$t('Registration number')"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575" :label="$t('Date of joining')"
               v-model="student.join_date"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" :label="$t('public record number')"
              v-model="student.public_record_number" outlined dense color="#757575" type="number"></v-text-field>
          </v-col>
          <v-col md="3" class="py-0">
            <v-radio-group :dark="$store.state.isDarkMode" v-model="student.gender" row :label="$t('gender')">
              <v-radio :label="$t('male')" value="male" color="#757575"></v-radio>
              <v-radio :label="$t('female')" value="female" color="#757575"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" :hint="$t('requird')" :label="$t('External transfer number')"
              v-model="student.number_transfer_out_document" outlined dense color="#757575"
              type="number"></v-text-field>
          </v-col>
          <v-col md="3" class="py-0">
            <v-radio-group :dark="$store.state.isDarkMode" v-model="student.type" row :label="$t('type')" @change="
              student.number_transfer_document = null;
            student.transfer_document_photo = null;
            ">
              <v-radio :label="$t('new')" value="new" color="#757575"></v-radio>
              <v-radio :label="$t('Transported')" value="transported" color="#757575"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('buffet_note')" v-model="student.buffet_note" outlined dense
              color="#757575"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-fade-transition mode="out-in">
              <v-text-field :dark="$store.state.isDarkMode" v-if="student.type == 'transported'" dense color="#757575" outlined
                :label="$t('transported number')" type="number"
                v-model="student.number_transfer_document"></v-text-field>
            </v-fade-transition>
          </v-col>
          <v-col md="3">
            <v-fade-transition mode="out-in">
              <v-text-field v-if="student.type == 'transported'" :dark="$store.state.isDarkMode" type="date" dense outlined color="#757575"
                  :label="$t('Transfer Date')" v-model="student.transfer_document_date"></v-text-field>
            </v-fade-transition>
          </v-col>
          <v-col md="7" class="d-flex justify-end pl-5">
            <v-btn color="green" class="white--text" width="150" depressed @click="submitStudent"
              :loading="submitBtnLoading">{{ $t("edit") }}</v-btn>
          </v-col>
          <v-col md="12" v-if="!fetchingImages">
            <v-row>
              <v-col md="4">
                <app-image-input-file :srcImage="student.student_photo" :createFeature="false" :imgHeight="365"
                  :imgWidth="350" :placeHolder="$t('student photo')"
                  @fileValue="setStudentPhoto($event, 'student_photo')"
                  @fileRemoved="deleteStudentPhoto('student_photo')"></app-image-input-file>
              </v-col>
              <v-col md="4">
                <v-row>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.family_book_1" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('Father Residence/ID Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_1')"
                      @fileRemoved="deleteStudentPhoto('family_book_1')"></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.family_book_2" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('Father Passport Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_2')"
                      @fileRemoved="deleteStudentPhoto('family_book_2')"></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.family_book_3" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('Student Residence/ID Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_3')"
                      @fileRemoved="deleteStudentPhoto('family_book_3')"></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.family_book_4" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('Student Passport Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_4')"
                      @fileRemoved="deleteStudentPhoto('family_book_4')"></app-image-input-file>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-row>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.vaccine_card" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('Vaccine card')"
                      @fileValue="setStudentPhoto($event, 'vaccine_card')"
                      @fileRemoved="deleteStudentPhoto('vaccine_card')"></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.study_sequence" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('student study sequence')"
                      @fileValue="setStudentPhoto($event, 'study_sequence')"
                      @fileRemoved="deleteStudentPhoto('study_sequence')"></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file :createFeature="false" :srcImage="student.medical_report" :imgHeight="170"
                      :imgWidth="150" :placeHolder="$t('medical report')"
                      @fileValue="setStudentPhoto($event, 'medical_report')"
                      @fileRemoved="deleteStudentPhoto('medical_report')"></app-image-input-file>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-fade-transition mode="out-in">
                  <app-image-input-file v-if="student.type == 'transported'" :createFeature="false"
                    :srcImage="student.transfer_document_photo" :imgHeight="170" :imgWidth="150"
                    :placeHolder="$t('Transfer document')" @fileRemoved="deleteStudentPhoto('transfer_document_photo')"
                    @fileValue="
              setStudentPhoto($event, 'transfer_document_photo')
              "></app-image-input-file>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import imageInputFile from "../../../../components/base/imageInputFile.vue";
import axios from "axios";
export default {
  components: {
    appImageInputFile: imageInputFile,
  },
  data() {
    return {
      birthdayDialog: false,
      joinDateDialog: false,
      fetchingImages: false,
      submitBtnLoading: false,
      studentId: this.$route.params.id,
      student: {
        gender: "male",
        type: "new",
        first_name: "",
        last_name: "",
        father_name: "",
        grand_father_name: "",
        mother_name: "",
        father_job: null,
        birthday: null,
        transfer_document_date: null,
        birth_location: "",
        join_date: null,
        foreign_language: "",
        nationality: "",
        student_email: "",
        parent_email: "",
        parent_passport_number: "",
        student_passport_number: "",
        address: "",
        student_contact: "",
        birth_registration_id: "",
        phone_number: null,
        mobile_number: null,
        number_transfer_document: null,
        public_record_number: null,
        buffet_note: "",
        // photos
        student_photo: null,
        family_book_1: null,
        family_book_2: null,
        family_book_3: null,
        family_book_4: null,
        father_photo_id: null,
        vaccine_card: null,
        study_sequence: null,
        medical_report: null,
        transfer_document_photo: null,
        number_transfer_out_document: null,
      },
    };
  },
  methods: {
    setStudentPhoto(file, photoType) {
      this.student[photoType] = file;
    },
    deleteStudentPhoto(photoType) {
      this.newStudent[photoType] = null;
    },
    submitStudent() {
      this.submitBtnLoading = true;
      this.$store
        .dispatch("editStudent/updateStudent", {
          student: this.student,
          studentId: this.studentId,
        })
        .then((_) => {
          this.$Notifications(
            this.$t('edit success'),
            { timeout: 2000, rtl: true },
            "success"
          );
        })
        .finally((_) => {
          this.submitBtnLoading = false;
          this.$router.go(-1);
        });
    },
  },
  async created() {
    try {
      this.fetchingImages = true;
      this.submitBtnLoading = true;
      const response = await axios.get(`/student/${this.studentId}`);

      let responseData = response.data.data;

      for (let key in responseData) {
        if (responseData[key] && this.student.hasOwnProperty(key)) {
          this.student[key] = responseData[key];
        }
      }
      console.log("??????", this.student);
    } catch (err) {
    } finally {
      this.fetchingImages = false;
      this.submitBtnLoading = false;
    }
  },
};
</script>

<style></style>
